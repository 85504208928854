import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Row, Col, Table, Modal, Input, Select, Button, Popconfirm, Tag, Space, Skeleton } from 'antd'
import { PlusOutlined, SearchOutlined, PrinterOutlined, EyeOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { genCsv } from '../../utils/csv'
import { search } from '../../utils/search'
import { applyJobObj, jobObj, healthCheckObj, statusObj, courseObj, militaryObj, colorBlindObj, levelObj, learningSystemObj, degreeObj } from '../../const/student'
import { nameTitleObj } from '../../const/users'
import { paymentObj } from '../../const/fee'
import { isAllowed } from '../../route/roles'

const List = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { getPageNo, onPageChange, getSearch, setSearch, getBranches } = useLayoutContext()

  const [updateCount, setUpdateCount] = useState(0)

  // const [btnLoading, setBtnLoading] = useState(false)
  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [qsVisible, setQsVisible] = useState(false)
  const [qs, setQs] = useState({})
  const [tags, setTags] = useState([])
  const [branchObj, setBranchObj] = useState({})

  const [searchForm] = AntdForm.useForm()

  const { loading } = useAsync(async () => {
    const branchData = await getBranches()
    const branchDataObj = branchData.reduce((acc, o) => {
      acc[o.id] = o.name
      return acc
    }, {})
    setBranchObj(branchDataObj)

    const snap = await db.collection('/users').where('role', '==', 'student').get()
    const studentData = snap.docs.map(doc => {
      const data = doc.data()
      const healthCheckStatus = (!data.healthcheck) ? 'no' : (dayjs().isBefore(dayjs(dbTodayjs(data.healthcheck)))) ? 'no' : 'yes'
      const startDate = (data?.startdate) ? dbTodayjs(data.startdate).set('hour', 0) : null
      const status = (data?.status !== 'learning') ? data.status : (!startDate || dayjs().isBefore(startDate, 'day')) ? 'interest' : 'learning'
      return {
        ...data,
        id: doc.id,
        job: (!data?.jobstatus) ? '' : (data.jobstatus !== 'pass') ? 'no' : 'yes',
        healthcheck: healthCheckStatus,
        status: status,
        prevStatus: data.status
      }
    })
    studentData.sort((x, y) => y.createddate.seconds - x.createddate.seconds)
    setInitValue(studentData)
    const q = getSearch('students')
    setQs(q || {})
  }, [updateCount])

  useEffect(() => {
    const tagList = Object.keys(qs).reduce((acc, o) => {
      return (!qs[o]) ? acc : [...acc, {
        key: o,
        value: (o === 'branchid') ? branchObj[qs[o]] : (o === 'lev') ? levelObj[qs[o]]?.name : (o === 'applyjob') ? applyJobObj[qs[o]]?.name : (o === 'job') ? jobObj[qs[o]]?.name : (o === 'healthcheck') ? healthCheckObj[qs[o]]?.name : (o === 'payment') ? paymentObj[qs[o]]?.name : (o === 'status') ? statusObj[qs[o]]?.name : (o === 'course') ? courseObj[qs[o]]?.name : qs[o],
        label: {
          scn: 'รหัสนักเรียน',
          firstname: 'ชื่อ',
          lastname: 'นามสกุล',
          branchid: 'สำนักงานที่รับผิดชอบ',
          lev: 'ระดับภาษา',
          applyjob: 'อนุญาตสัมภาษณ์งาน',
          job: 'สถานะการได้งาน',
          healthcheck: 'ตรวจสุขภาพ',
          payment: 'ค่าลงทะเบียน',
          status: 'สถานะ',
          course: 'หลักสูตร'
        }[o]
      }]
    }, [])
    setTags(tagList)
    const data = search(initValue, qs)
    setValue(data)
  }, [initValue, qs, branchObj])

  const handleClose = (key) => {
    const newQs = {
      ...qs,
      [key]: null
    }
    setQs(newQs)
    setSearch('students', newQs)
    searchForm.setFieldsValue(newQs)
  }

  const handleDelete = async (row) => {
    try {
      await db.collection('/users').doc(row.id).delete()
      await db.collection('/studentinfo').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // const handleReverify = async (row) => {
  //   try {
  //     const reverifyUser = functions.httpsCallable('reVerifyUser-reVerifyUser')
  //     await reverifyUser({ email: row.email })
  //     return Promise.resolve()
  //   } catch (error) {
  //     return Promise.reject(error)
  //   }
  // }

  const download = async (data) => {
    const list = data.map((o) => ({
      'รหัสนักเรียน': o?.scn ?? '',
      'หลักสูตร': courseObj[o?.course]?.name ?? '',
      'คำนำหน้า': nameTitleObj[o?.title]?.nameTh ?? '',
      'ชื่อ': o?.firstname ?? '',
      'นามสกุล': o?.lastname ?? '',
      'เบอร์ติดต่อ': o?.tel ?? '',
      'ระดับภาษา': levelObj[o?.lev]?.name ?? '',
      'สำนักงานที่รับผิดชอบ': branchObj[o?.branchid] ?? '',
      'อนุญาตสัมภาษณ์งาน': applyJobObj[o?.applyjob]?.name ?? '',
      'สถานะการได้งาน': jobObj[o?.job]?.name ?? '',
      'ตรวจสุขภาพ': healthCheckObj[o?.healthcheck]?.name ?? '',
      'ตาบอดสี': colorBlindObj[o?.colorblind]?.name ?? '',
      'สถานะทางทหาร': militaryObj[o?.military]?.name ?? '',
      'ระบบการเรียน': learningSystemObj[o?.learningsystem]?.name ?? '',
      'วุฒิการศึกษา': degreeObj[o?.degree]?.name ?? '',
      'สถานศึกษา': o?.school ?? '',
      'ค่าลงทะเบียน': paymentObj[o?.payment]?.name ?? '',
      'สถานะ': statusObj[o?.status]?.name ?? ''
    }))
    try {
      await genCsv('student_list', list)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // const excuteScript = async () => {
  //   try {
  //     setBtnLoading(true)
  //     const updateTraveldate = functions.httpsCallable('updateTraveldate-updateTraveldate')
  //     await updateTraveldate()
  //     const changeSCN = functions.httpsCallable('changeSCN-changeSCN')
  //     await changeSCN()
  //     setBtnLoading(false)
  //     return Promise.resolve()
  //   } catch (error) {
  //     setBtnLoading(false)
  //     return Promise.reject(error)
  //   }
  // }

  const columns = [
    {
      title: 'รหัสนักเรียน',
      dataIndex: 'scn',
      width: 110
    },
    {
      title: 'หลักสูตร',
      dataIndex: 'course',
      width: 100,
      render: data => (
        <span>{courseObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'คำนำหน้า',
      dataIndex: 'title',
      width: 50,
      render: data => nameTitleObj[data]?.nameTh ?? ''
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname',
      width: 100,
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname',
      width: 100,
    },
    {
      title: 'เบอร์ติดต่อ',
      dataIndex: 'tel',
      width: 120
    },
    {
      title: 'ระดับภาษา',
      dataIndex: 'lev',
      width: 110,
      render: data => (
        <span>{levelObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'สำนักงานที่รับผิดชอบ',
      dataIndex: 'branchid',
      width: 110,
      render: data => (
        <span>{branchObj[data] ?? ''}</span>
      )
    },
    {
      title: 'อนุญาตสัมภาษณ์งาน',
      dataIndex: 'applyjob',
      width: 110,
      render: data => (
        <span style={{ color: applyJobObj[data]?.color ?? '#000000' }}>{applyJobObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'สถานะการได้งาน',
      dataIndex: 'job',
      width: 110,
      render: data => (
        <span style={{ color: jobObj[data]?.color ?? '#000000' }}>{jobObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'ตรวจสุขภาพ',
      dataIndex: 'healthcheck',
      width: 110,
      render: data => (
        <span style={{ color: healthCheckObj[data]?.color ?? '#000000' }}>{healthCheckObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'ตาบอดสี',
      dataIndex: 'colorblind',
      width: 110,
      render: data => (
        <span>{colorBlindObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'สถานะทางทหาร',
      dataIndex: 'military',
      width: 110,
      render: data => (
        <span>{militaryObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'ระบบการเรียน',
      dataIndex: 'learningsystem',
      width: 110,
      render: data => (
        <span>{learningSystemObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'ค่าลงทะเบียน',
      dataIndex: 'payment',
      width: 110,
      render: data => (
        <span style={{ color: paymentObj[data]?.color ?? '' }}>{paymentObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: 120,
      render: data => (
        <span style={{ color: statusObj[data]?.color ?? '#000000' }}>{statusObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          {(isAllowed(role, 'view', 'students')) && (
            <Button
              type='link'
              style={{ marginRight: '8px' }}
              icon={<EyeOutlined style={{ fontSize: 18 }} />}
              onClick={() => {
                navigate(`/admin/students/${row.id}/view`)
              }}
            ></Button>
          )}
          {((isAllowed(role, 'delete', 'students')) && (!row?.prevStatus || (['interest', 'notInterest'].indexOf(row.prevStatus) !== -1))) && (
            <Popconfirm
              title={`Are you sure to delete ${row.email} user?`}
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                style={{ marginRight: '8px' }}
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )}
          {/* {((isAllowed(role, 'edit', 'students')) && (!row?.emailVerified && (row.status !== 'notInterest'))) && (
            <Popconfirm
              title={`You want to reverify ${row.email} again?`}
              onConfirm={() => handleReverify(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                icon={<ReloadOutlined style={{ fontSize: 18, color: 'aqua' }} />}
              ></Button>
            </Popconfirm>
          )} */}
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            นักเรียน
          </div>
          <div style={{ marginTop: 12 }}>
            {tags.map((o) => (
              <Tag
                key={o.key}
                closable
                onClose={() => handleClose(o.key)}
              >
                {o.label}: {o.value}
              </Tag>
            ))}
          </div>
        </Col>
        <Col flex='500px'>
          <div style={{ float: 'right', fontSize: 14 }}>
            <div>
              {/* {(role === 'superadmin') && (
                <Button
                  type='primary'
                  style={{ marginRight: 8, backgroundColor: '#fa5d16' }}
                  icon={<EditOutlined style={{ fontSize: 14 }} />}
                  loading={btnLoading}
                  onClick={() => {
                    excuteScript()
                  }}
                >
                  Run Script
                </Button>
              )} */}
              {(isAllowed(role, 'edit', 'studentSCN')) && (
                <Button
                  type='primary'
                  style={{ marginRight: 8, backgroundColor: '#fa8c16' }}
                  icon={<EditOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate('/admin/students/update-scn')
                  }}
                >
                  เปลี่ยนรหัสนักเรียน
                </Button>
              )}
              {(isAllowed(role, 'create', 'students')) && (
                <Button
                  type='primary'
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate('/admin/students/create')
                  }}
                >
                  สร้างนักเรียน
                </Button>
              )}
            </div>
            <div style={{ float: 'right', margin: '8px 0 16px 0' }}>
              <Button
                icon={<SearchOutlined />}
                style={{ margin: '0 8px' }}
                onClick={() => {
                  setQsVisible(true)
                }}
              ></Button>
              <Button
                icon={<PrinterOutlined />}
                onClick={() => {
                  download(value)
                }}
              ></Button>
            </div>
          </div>
        </Col>
      </Row >
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('students'),
          onChange(page) {
            onPageChange('students', page)
          }
        }}
      />
      <div style={{ marginTop: 24, fontSize: 20 }}>
        จำนวนนักเรียน <span style={{ fontWeight: 'bold' }}>{value.length}</span> คน<br />
        จำนวนนักเรียนทั้งหมด <span style={{ fontWeight: 'bold' }}>{initValue.length}</span> คน
      </div>
      <Modal
        forceRender
        open={qsVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ค้นหา
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      scn: null,
                      status: null,
                      firstname: null,
                      lastname: null,
                      branchid: null,
                      healthcheck: null,
                      payment: null,
                      applyjob: null,
                      job: null,
                      lev: null,
                      course: null
                    }
                    setQs(empty)
                    setSearch('students', empty)
                    searchForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setQsVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = searchForm.getFieldsValue()
                    setQs(data)
                    setSearch('students', data)
                    setQsVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={qs}
          style={{ margin: '8px 32px' }}
          form={searchForm}
          name='searchForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='scn' label='รหัสนักเรียน' >
                <Input placeholder='รหัสนักเรียน' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='status' label='สถานะ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(statusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='firstname' label='ชื่อ' >
                <Input placeholder='ชื่อ' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='lastname' label='นามสกุล' >
                <Input placeholder='นามสกุล' />
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='branchid' label='สำนักงานที่รับผิดชอบ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(branchObj)).map((o) => (
                    <Select.Option key={o} value={o}>{branchObj[o]}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='healthcheck' label='ตรวจสุขภาพ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(healthCheckObj)).map((o) => (
                    <Select.Option key={o} value={o}>{healthCheckObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='payment' label='ค่าลงทะเบียนเรียน' >
                <Select placeholder='เลือก' >
                  {((Object.keys(paymentObj)).map((o) => (
                    <Select.Option key={o} value={o}>{paymentObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='applyjob' label='อนุญาตสัมภาษณ์งาน' >
                <Select placeholder='เลือก' >
                  {((Object.keys(applyJobObj)).map((o) => (
                    <Select.Option key={o} value={o}>{applyJobObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='job' label='สถานะการได้งาน' >
                <Select placeholder='เลือก' >
                  {((Object.keys(jobObj)).map((o) => (
                    <Select.Option key={o} value={o}>{jobObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='lev' label='ระดับภาษา' >
                <Select placeholder='เลือก' >
                  {((Object.keys(levelObj)).map((o) => (
                    <Select.Option key={o} value={o}>{levelObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='course' label='หลักสูตร' >
                <Select placeholder='เลือก' >
                  {((Object.keys(courseObj)).map((o) => (
                    <Select.Option key={o} value={o}>{courseObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
        </AntdForm>
      </Modal>
    </Card >
  )
}

export default List

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Card, Row, Col, Table, Button, Skeleton } from 'antd'
import { PlusOutlined, EyeOutlined } from '@ant-design/icons'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../../contexts/LayoutContextProvider'
import { isAllowed } from '../../../route/roles'

const List = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { getPageNo, onPageChange } = useLayoutContext()

  // const [updateCount, setUpdateCount] = useState(0)

  const { value, loading } = useAsync(async () => {
    const snap = await db.collection('/lenders').get()
    return snap.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id
      }
    })
  })
  // }, [updateCount])

  // const handleDelete = async (row) => {
  //   try {
  //     await db.collection('/lenders').doc(row.id).delete()
  //     setUpdateCount(updateCount + 1)
  //     return Promise.resolve()
  //   } catch (error) {
  //     return Promise.reject(error)
  //   }
  // }

  const columns = [
    {
      title: 'เจ้าหนี้กองทุน',
      dataIndex: 'name'
    },
    {
      title: 'เบอร์ติดต่อ',
      dataIndex: 'tel',
      width: 200
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              navigate(`/admin/masters/lenders/${row.id}/edit`)
            }}
          ></Button>
          {/* {(isAllowed(role, 'delete', 'masters', 'lenders')) && (
            <Popconfirm
              title={`Are you sure to delete ${row.name} lender?`}
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                style={{ marginRight: '8px' }}
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )} */}
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            เจ้าหนี้กองทุน
          </div>
        </Col>
        <Col flex='300px'>
          <div style={{ float: 'right', fontSize: 14 }}>
            {(isAllowed(role, 'create', 'masters', 'lenders')) && (
              <div>
                <Button
                  type='primary'
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate('/admin/masters/lenders/create')
                  }}
                >
                  เพิ่มเจ้าหนี้กองทุน
                </Button>
              </div>
            )}
            <div style={{ float: 'right', margin: '8px 0 16px 0' }}>
              <div style={{ margin: '24px 0' }}></div>
            </div>
          </div>
        </Col>
      </Row >
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('lenders'),
          onChange(page) {
            onPageChange('lenders', page)
          }
        }}
      />
    </Card >
  )
}

export default List

import React from 'react'
import { Routes, Route } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import { roleObj } from './roles'

import AuthLayout from '../layouts/AuthLayout/AuthLayout'
import Login from '../pages/LoginPage/Login'
// import Register from '../pages/RegisterPage/Register'
import AuthAction from '../pages/AuthActionPage/AuthAction'
import ForgetPassword from '../pages/ForgetPasswordPage/ForgetPassword'
import AdminLayout from '../layouts/AdminLayout/AdminLayout'
import UserPage from '../pages/UserPage/UserPage'
import UserList from '../pages/UserPage/List'
import UserForm from '../pages/UserPage/Form'
import StudentPage from '../pages/StudentPage/StudentPage'
// import StudentStudentView from '../pages/StudentPage/StudentView'
import StudentList from '../pages/StudentPage/List'
import StudentForm from '../pages/StudentPage/Form'
import StudentView from '../pages/StudentPage/View'
import StudentStatusForm from '../pages/StudentPage/StatusForm'
import StudentInfoThForm from '../pages/StudentPage/InfoThForm'
import StudentInfoJpForm from '../pages/StudentPage/InfoJpForm'
import StudentDocumentList from '../pages/StudentPage/DocumentList'
import StudentDocumentForm from '../pages/StudentPage/DocumentForm'
import StudentClassList from '../pages/StudentPage/ClassList'
import StudentClassForm from '../pages/StudentPage/ClassForm'
import StudentBehaviorList from '../pages/StudentPage/BehaviorList'
import StudentBehaviorForm from '../pages/StudentPage/BehaviorForm'
import StudentInterviewList from '../pages/StudentPage/InterviewList'
import StudentInterviewForm from '../pages/StudentPage/InterviewForm'
import StudentFundList from '../pages/StudentPage/FundList'
import StudentFundForm from '../pages/StudentPage/FundForm'
import StudentSCNUpdate from '../pages/StudentPage/StudentSCNUpdate'
import ClassPage from '../pages/ClassPage/ClassPage'
import ClassList from '../pages/ClassPage/List'
import ClassForm from '../pages/ClassPage/Form'
import ClassView from '../pages/ClassPage/View'
import ClassStudentEvaluate from '../pages/ClassPage/Evaluate'
import ClassStudentBehavior from '../pages/ClassPage/Behavior'
import JobPage from '../pages/JobPage/JobPage'
import JobList from '../pages/JobPage/List'
import JobForm from '../pages/JobPage/Form'
import JobView from '../pages/JobPage/View'
import JobStudentForm from '../pages/JobPage/StudentForm'
import JobStudentView from '../pages/JobPage/StudentView'
import JobStudentInterview from '../pages/JobPage/StudentInterview'
import RoomPage from '../pages/RoomPage/RoomPage'
import RoomList from '../pages/RoomPage/List'
import RoomForm from '../pages/RoomPage/Form'
import RoomView from '../pages/RoomPage/View'
// import RoomStudentForm from '../pages/RoomPage/StudentForm'
import FeePage from '../pages/FeePage/FeePage'
import FeeList from '../pages/FeePage/List'
import FeeForm from '../pages/FeePage/Form'
import FeeView from '../pages/FeePage/View'
import FeePaymentForm from '../pages/FeePage/PaymentForm'
import JPStudentPage from '../pages/JPStudentPage/JPStudentPage'
import JPStudentList from '../pages/JPStudentPage/List'
import JPStudentForm from '../pages/JPStudentPage/Form'
import InvoicePage from '../pages/InvoicePage/InvoicePage'
import InvoiceList from '../pages/InvoicePage/List'
import InvoiceForm from '../pages/InvoicePage/Form'
import InvoiceView from '../pages/InvoicePage/View'
import FundPage from '../pages/FundPage/FundPage'
import FundList from '../pages/FundPage/List'
import FundForm from '../pages/FundPage/Form'
import FundView from '../pages/FundPage/View'
import FundPayment from '../pages/FundPage/Payment'
import CreditorPage from '../pages/CreditorPage/CreditorPage'
import CreditorList from '../pages/CreditorPage/List'
import CreditorView from '../pages/CreditorPage/View'
import InstallmentPage from '../pages/InstallmentPage/InstallmentPage'
import InstallmentList from '../pages/InstallmentPage/List'
import InstallmentForm from '../pages/InstallmentPage/Form'
import InstallmentView from '../pages/InstallmentPage/View'
import InstallmentPaymentForm from '../pages/InstallmentPage/PaymentForm'
import ComplainPage from '../pages/ComplainPage/ComplainPage'
import ComplainList from '../pages/ComplainPage/List'
import ComplainForm from '../pages/ComplainPage/Form'
import MasterPage from '../pages/MasterPage/MasterPage'
import CampusPage from '../pages/MasterPage/CampusPage/CampusPage'
import CampusList from '../pages/MasterPage/CampusPage/List'
import CampusForm from '../pages/MasterPage/CampusPage/Form'
import BranchPage from '../pages/MasterPage/BranchPage/BranchPage'
import BranchList from '../pages/MasterPage/BranchPage/List'
import BranchForm from '../pages/MasterPage/BranchPage/Form'
import CoopPage from '../pages/MasterPage/CoopPage/CoopPage'
import CoopList from '../pages/MasterPage/CoopPage/List'
import CoopForm from '../pages/MasterPage/CoopPage/Form'
import CompanyPage from '../pages/MasterPage/CompanyPage/CompanyPage'
import CompanyList from '../pages/MasterPage/CompanyPage/List'
import CompanyForm from '../pages/MasterPage/CompanyPage/Form'
import SubjectPage from '../pages/MasterPage/SubjectPage/SubjectPage'
import SubjectList from '../pages/MasterPage/SubjectPage/List'
import SubjectForm from '../pages/MasterPage/SubjectPage/Form'
import LenderPage from '../pages/MasterPage/LenderPage/LenderPage'
import LenderList from '../pages/MasterPage/LenderPage/List'
import LenderForm from '../pages/MasterPage/LenderPage/Form'
import AssessmentPage from '../pages/MasterPage/AssessmentPage/AssessmentPage'
import AssessmentList from '../pages/MasterPage/AssessmentPage/List'
import AssessmentForm from '../pages/MasterPage/AssessmentPage/Form'
import EvaluationPage from '../pages/MasterPage/EvaluationPage/EvaluationPage'
import EvaluationList from '../pages/MasterPage/EvaluationPage/List'
import EvaluationForm from '../pages/MasterPage/EvaluationPage/Form'
import QuestionPage from '../pages/MasterPage/QuestionPage/QuestionPage'
import QuestionList from '../pages/MasterPage/QuestionPage/List'
import QuestionForm from '../pages/MasterPage/QuestionPage/Form'

import Forbidden from '../pages/403/Forbidden'
import NotFound from '../pages/404/NotFound'

const Index = () => {
  return (
    <Routes>
      {/* <Route path='/register' element={<Register />} /> */}
      <Route path='/' element={<AuthLayout />} >
        <Route index element={<Login />} />
        <Route path='login' element={<Login />} />
        <Route path='auth/action' element={<AuthAction />} />
        <Route path='forget-password' element={<ForgetPassword />} />
      </Route>
      <Route path='/admin' element={<AdminLayout />} >
        {/* <Route
          path='student'
          element={
            <PrivateRoute roles={roleObj['view']['student']}>
              <StudentPage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<StudentStudentView />}
          />
          <Route
            path='info-th/view'
            element={<StudentInfoThForm />}
          />
          <Route
            path='info-jp/view'
            element={<StudentInfoJpForm />}
          />
          <Route
            path='documents'
            element={<StudentDocumentList />}
          />
          <Route
            path='documents/:did/view'
            element={<StudentDocumentForm />}
          />
          <Route
            path='classes'
            element={<StudentClassList />}
          />
          <Route
            path='classes/:sid/view'
            element={<StudentClassForm />}
          />
          <Route
            path='behaviors'
            element={<StudentBehaviorList />}
          />
          <Route
            path='behaviors/:bid/view'
            element={<StudentBehaviorForm />}
          />
          <Route
            path='interviews'
            element={<StudentInterviewList />}
          />
          <Route
            path='interviews/:sid/view'
            element={<StudentInterviewForm />}
          />
          <Route
            path='funds'
            element={<StudentFundList />}
          />
          <Route
            path='funds/:fid/view'
            element={<StudentFundForm />}
          />
        </Route> */}
        <Route
          path='users'
          element={
            <PrivateRoute roles={roleObj['view']['users']}>
              <UserPage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<UserList />}
          />
          <Route
            path='create'
            element={
              <PrivateRoute roles={roleObj['create']['users']}>
                <UserForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':uid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['users']}>
                <UserForm />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path='students'
          element={
            <PrivateRoute roles={roleObj['view']['students']}>
              <StudentPage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<StudentList />}
          />
          <Route
            path='create'
            element={
              <PrivateRoute roles={roleObj['create']['students']}>
                <StudentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['students']}>
                <StudentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/view'
            element={
              <PrivateRoute roles={roleObj['view']['students']}>
                <StudentView />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/status'
            element={
              <PrivateRoute roles={roleObj['edit']['students']}>
                <StudentStatusForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/info-th/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['infoTh']}>
                <StudentInfoThForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/info-th/view'
            element={
              <PrivateRoute roles={roleObj['view']['infoTh']}>
                <StudentInfoThForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/info-jp/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['infoJp']}>
                <StudentInfoJpForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/info-jp/view'
            element={
              <PrivateRoute roles={roleObj['view']['infoJp']}>
                <StudentInfoJpForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/documents'
            element={
              <PrivateRoute roles={roleObj['view']['documents']}>
                <StudentDocumentList />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/documents/create'
            element={
              <PrivateRoute roles={roleObj['create']['documents']}>
                <StudentDocumentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/documents/:did/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['documents']}>
                <StudentDocumentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/documents/:did/view'
            element={
              <PrivateRoute roles={roleObj['view']['documents']}>
                <StudentDocumentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/classes'
            element={
              <PrivateRoute roles={roleObj['view']['studentClasses']}>
                <StudentClassList />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/classes/:sid/view'
            element={
              <PrivateRoute roles={roleObj['view']['studentClasses']}>
                <StudentClassForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/behaviors'
            element={
              <PrivateRoute roles={roleObj['view']['studentBehaviors']}>
                <StudentBehaviorList />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/behaviors/create'
            element={
              <PrivateRoute roles={roleObj['create']['studentBehaviors']}>
                <StudentBehaviorForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/behaviors/:bid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['studentBehaviors']}>
                <StudentBehaviorForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/behaviors/:bid/view'
            element={
              <PrivateRoute roles={roleObj['view']['studentBehaviors']}>
                <StudentBehaviorForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/interviews'
            element={
              <PrivateRoute roles={roleObj['view']['studentInterviews']}>
                <StudentInterviewList />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/interviews/create'
            element={
              <PrivateRoute roles={roleObj['create']['studentInterviews']}>
                <StudentInterviewForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/interviews/:sid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['studentInterviews']}>
                <StudentInterviewForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/interviews/:sid/view'
            element={
              <PrivateRoute roles={roleObj['view']['studentInterviews']}>
                <StudentInterviewForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/funds'
            element={
              <PrivateRoute roles={roleObj['view']['studentFunds']}>
                <StudentFundList />
              </PrivateRoute>
            }
          />
          <Route
            path=':id/funds/:fid/view'
            element={
              <PrivateRoute roles={roleObj['view']['studentFunds']}>
                <StudentFundForm />
              </PrivateRoute>
            }
          />
          <Route
            path='update-scn'
            element={
              <PrivateRoute roles={roleObj['edit']['studentSCN']}>
                <StudentSCNUpdate />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path='classes'
          element={
            <PrivateRoute roles={roleObj['view']['classes']}>
              <ClassPage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<ClassList />}
          />
          <Route
            path='create'
            element={
              <PrivateRoute roles={roleObj['create']['classes']}>
                <ClassForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':cid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['classes']}>
                <ClassForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':cid/view'
            element={<ClassView />}
          />
          <Route
            path=':cid/student/:sid/evaluate'
            element={
              <PrivateRoute roles={roleObj['edit']['classes']}>
                <ClassStudentEvaluate />
              </PrivateRoute>
            }
          />
          <Route
            path=':cid/student/:uid/behavior'
            element={
              <PrivateRoute roles={roleObj['edit']['classes']}>
                <ClassStudentBehavior />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path='jobs'
          element={
            <PrivateRoute roles={roleObj['view']['jobs']}>
              <JobPage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<JobList />}
          />
          <Route
            path='create'
            element={
              <PrivateRoute roles={roleObj['create']['jobs']}>
                <JobForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':jid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['jobs']}>
                <JobForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':jid/view'
            element={<JobView />}
          />
          <Route
            path=':jid/students'
            element={
              <PrivateRoute roles={roleObj['edit']['jobs']}>
                <JobStudentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':jid/student/:uid/view'
            element={<JobStudentView />}
          />
          <Route
            path=':jid/student/:sid/interview'
            element={
              <PrivateRoute roles={roleObj['edit']['jobs']}>
                <JobStudentInterview />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path='rooms'
          element={
            <PrivateRoute roles={roleObj['view']['rooms']}>
              <RoomPage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<RoomList />}
          />
          <Route
            path='create'
            element={
              <PrivateRoute roles={roleObj['create']['rooms']}>
                <RoomForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':rid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['rooms']}>
                <RoomForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':rid/view'
            element={<RoomView />}
          />
          {/* <Route
            path=':rid/students'
            element={
              <PrivateRoute roles={roleObj['edit']['rooms']}>
                <RoomStudentForm />
              </PrivateRoute>
            }
          /> */}
        </Route>
        <Route
          path='fees'
          element={
            <PrivateRoute roles={roleObj['view']['fees']}>
              <FeePage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<FeeList />}
          />
          <Route
            path='create'
            element={
              <PrivateRoute roles={roleObj['create']['fees']}>
                <FeeForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':uid/view'
            element={<FeeView />}
          />
          <Route
            path=':uid/payment/create'
            element={
              <PrivateRoute roles={roleObj['edit']['fees']}>
                <FeePaymentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':uid/payment/:pid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['fees']}>
                <FeePaymentForm />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path='jpstudents'
          element={
            <PrivateRoute roles={roleObj['view']['jpstudents']}>
              <JPStudentPage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<JPStudentList />}
          />
          <Route
            path='create'
            element={
              <PrivateRoute roles={roleObj['create']['jpstudents']}>
                <JPStudentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':jid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['jpstudents']}>
                <JPStudentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':jid/view'
            element={<JPStudentForm />}
          />
        </Route>
        <Route
          path='invoices'
          element={
            <PrivateRoute roles={roleObj['view']['invoices']}>
              <InvoicePage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<InvoiceList />}
          />
          <Route
            path=':cid/:type/create'
            element={
              <PrivateRoute roles={roleObj['create']['invoices']}>
                <InvoiceForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':cid/:type/:iid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['invoices']}>
                <InvoiceForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':cid/:type/:iid/copy'
            element={
              <PrivateRoute roles={roleObj['edit']['invoices']}>
                <InvoiceForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':cid/view'
            element={<InvoiceView />}
          />
        </Route>
        <Route
          path='funds'
          element={
            <PrivateRoute roles={roleObj['view']['funds']} param='type'>
              <FundPage />
            </PrivateRoute>
          }
        >
          <Route
            path=':type'
            element={<FundList />}
          />
          <Route
            path=':type/create'
            element={
              <PrivateRoute roles={roleObj['create']['funds']} param='type'>
                <FundForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':type/:fid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['funds']} param='type'>
                <FundForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':type/:fid/view'
            element={<FundView />}
          />
          <Route
            path=':type/:fid/payment'
            element={
              <PrivateRoute roles={roleObj['edit']['funds']} param='type'>
                <FundPayment />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path='creditors'
          element={
            <PrivateRoute roles={roleObj['view']['creditors']}>
              <CreditorPage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<CreditorList />}
          />
          <Route
            path=':cid/view'
            element={<CreditorView />}
          />
        </Route>
        <Route
          path='installments'
          element={
            <PrivateRoute roles={roleObj['view']['installments']}>
              <InstallmentPage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<InstallmentList />}
          />
          <Route
            path=':iid/create'
            element={
              <PrivateRoute roles={roleObj['create']['installments']}>
                <InstallmentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':iid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['installments']}>
                <InstallmentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':iid/view'
            element={<InstallmentView />}
          />
          <Route
            path=':iid/payment/create'
            element={
              <PrivateRoute roles={roleObj['edit']['installments']}>
                <InstallmentPaymentForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':iid/payment/:pid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['installments']}>
                <InstallmentPaymentForm />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path='complains'
          element={
            <PrivateRoute roles={roleObj['view']['complains']}>
              <ComplainPage />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={<ComplainList />}
          />
          <Route
            path='create'
            element={
              <PrivateRoute roles={roleObj['create']['complains']}>
                <ComplainForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':cid/edit'
            element={
              <PrivateRoute roles={roleObj['edit']['complains']}>
                <ComplainForm />
              </PrivateRoute>
            }
          />
          <Route
            path=':cid/view'
            element={<ComplainForm />}
          />
        </Route>
        <Route
          path='masters'
          element={
            <PrivateRoute roles={roleObj['view']['masters']['master']}>
              <MasterPage />
            </PrivateRoute>
          }
        >
          <Route
            path='campuses'
            element={
              <PrivateRoute roles={roleObj['view']['masters']['campuses']}>
                <CampusPage />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute roles={roleObj['view']['masters']['campuses']}>
                  <CampusList />
                </PrivateRoute>
              }
            />
            <Route
              path='create'
              element={
                <PrivateRoute roles={roleObj['create']['masters']['campuses']}>
                  <CampusForm />
                </PrivateRoute>
              }
            />
            <Route
              path=':cid/edit'
              element={
                <PrivateRoute roles={roleObj['edit']['masters']['campuses']}>
                  <CampusForm />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path='branches'
            element={
              <PrivateRoute roles={roleObj['view']['masters']['branches']}>
                <BranchPage />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute roles={roleObj['view']['masters']['branches']}>
                  <BranchList />
                </PrivateRoute>
              }
            />
            <Route
              path='create'
              element={
                <PrivateRoute roles={roleObj['create']['masters']['branches']}>
                  <BranchForm />
                </PrivateRoute>
              }
            />
            <Route
              path=':bid/edit'
              element={
                <PrivateRoute roles={roleObj['edit']['masters']['branches']}>
                  <BranchForm />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path='assessments'
            element={
              <PrivateRoute roles={roleObj['view']['masters']['assessments']}>
                <AssessmentPage />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute roles={roleObj['view']['masters']['assessments']}>
                  <AssessmentList />
                </PrivateRoute>
              }
            />
            <Route
              path='create'
              element={
                <PrivateRoute roles={roleObj['create']['masters']['assessments']}>
                  <AssessmentForm />
                </PrivateRoute>
              }
            />
            <Route
              path=':aid/edit'
              element={
                <PrivateRoute roles={roleObj['edit']['masters']['assessments']}>
                  <AssessmentForm />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path='evaluations'
            element={
              <PrivateRoute roles={roleObj['view']['masters']['evaluations']}>
                <EvaluationPage />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute roles={roleObj['view']['masters']['evaluations']}>
                  <EvaluationList />
                </PrivateRoute>
              }
            />
            <Route
              path='create'
              element={
                <PrivateRoute roles={roleObj['create']['masters']['evaluations']}>
                  <EvaluationForm />
                </PrivateRoute>
              }
            />
            <Route
              path=':eid/edit'
              element={
                <PrivateRoute roles={roleObj['edit']['masters']['evaluations']}>
                  <EvaluationForm />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path='questions'
            element={
              <PrivateRoute roles={roleObj['view']['masters']['questions']}>
                <QuestionPage />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute roles={roleObj['view']['masters']['questions']}>
                  <QuestionList />
                </PrivateRoute>
              }
            />
            <Route
              path='create'
              element={
                <PrivateRoute roles={roleObj['create']['masters']['questions']}>
                  <QuestionForm />
                </PrivateRoute>
              }
            />
            <Route
              path=':qid/edit'
              element={
                <PrivateRoute roles={roleObj['edit']['masters']['questions']}>
                  <QuestionForm />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path='coops'
            element={
              <PrivateRoute roles={roleObj['view']['masters']['coops']}>
                <CoopPage />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute roles={roleObj['view']['masters']['coops']}>
                  <CoopList />
                </PrivateRoute>
              }
            />
            <Route
              path='create'
              element={
                <PrivateRoute roles={roleObj['create']['masters']['coops']}>
                  <CoopForm />
                </PrivateRoute>
              }
            />
            <Route
              path=':cid/edit'
              element={
                <PrivateRoute roles={roleObj['edit']['masters']['coops']}>
                  <CoopForm />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path='companies'
            element={
              <PrivateRoute roles={roleObj['view']['masters']['companies']}>
                <CompanyPage />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute roles={roleObj['view']['masters']['companies']}>
                  <CompanyList />
                </PrivateRoute>
              }
            />
            <Route
              path='create'
              element={
                <PrivateRoute roles={roleObj['create']['masters']['companies']}>
                  <CompanyForm />
                </PrivateRoute>
              }
            />
            <Route
              path=':cid/edit'
              element={
                <PrivateRoute roles={roleObj['edit']['masters']['companies']}>
                  <CompanyForm />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path='subjects'
            element={
              <PrivateRoute roles={roleObj['view']['masters']['subjects']}>
                <SubjectPage />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute roles={roleObj['view']['masters']['subjects']}>
                  <SubjectList />
                </PrivateRoute>
              }
            />
            <Route
              path='create'
              element={
                <PrivateRoute roles={roleObj['create']['masters']['subjects']}>
                  <SubjectForm />
                </PrivateRoute>
              }
            />
            <Route
              path=':sid/edit'
              element={
                <PrivateRoute roles={roleObj['edit']['masters']['subjects']}>
                  <SubjectForm />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path='lenders'
            element={
              <PrivateRoute roles={roleObj['view']['masters']['lenders']}>
                <LenderPage />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute roles={roleObj['view']['masters']['lenders']}>
                  <LenderList />
                </PrivateRoute>
              }
            />
            <Route
              path='create'
              element={
                <PrivateRoute roles={roleObj['create']['masters']['lenders']}>
                  <LenderForm />
                </PrivateRoute>
              }
            />
            <Route
              path=':lid/edit'
              element={
                <PrivateRoute roles={roleObj['edit']['masters']['lenders']}>
                  <LenderForm />
                </PrivateRoute>
              }
            />
          </Route>
        </Route>
      </Route>
      <Route path='/403' element={<Forbidden />} />
      <Route path='/404' element={<NotFound />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default Index

import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Table, Image, Popconfirm, Modal, Space, Input, Select, Skeleton } from 'antd'
import { PlusOutlined, DownloadOutlined, EditOutlined, DeleteOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { genPdf } from '../../utils/pdf'
import { toDataURL } from '../../utils/tools'
import { isAllowed } from '../../route/roles'

import NoProfile from '../../assets/images/user.png'
import Empty from '../../assets/images/empty.png'

const View = () => {
  const navigate = useNavigate()
  const { firebaseApp, db, role } = useAuth()
  const { getCampuses, getCoops, getCompanies } = useLayoutContext()
  const { rid } = useParams()

  const [loading, setLoading] = useState(false)
  const [updateCount, setUpdateCount] = useState(0)
  const [coop, setCoop] = useState()
  const [company, setCompany] = useState()
  const [students, setStudents] = useState([])
  const [inStudents, setInStudents] = useState([])
  const [studentList, setStudentList] = useState()
  const [studentVisible, setStudentVisible] = useState(false)
  const [ordVisible, setOrdVisible] = useState(false)
  const [remainBed, setRemainBed] = useState([])

  const [studentForm] = AntdForm.useForm()
  const [orderingForm] = AntdForm.useForm()

  const { value } = useAsync(async () => {
    if (!rid) {
      return {}
    }

    const coopData = await getCoops()
    const coopObj = coopData.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCoop(coopObj)

    const companyData = await getCompanies()
    const companyObj = companyData.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCompany(companyObj)

    const campusData = await getCampuses()
    const campusObj = campusData.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})

    const studentSnap = await db.collection('/users').where('role', '==', 'student').where('disabled', '==', false).where('status', 'in', ['interest', 'learning', 'suspension']).get()
    const studentData = studentSnap.docs.reduce((acc, doc) => {
      const o = doc.data()
      return (o?.rid) ? acc : [...acc, {
        ...o,
        id: doc.id,
        key: doc.id,
        scn: o?.scn ?? ''
      }]
    }, [])
    studentData.sort((x, y) => parseInt((x.scn !== '') ? x.scn : 9999999999999) - parseInt((y.scn !== '') ? y.scn : 9999999999999))
    setStudentList(studentData)

    const rords = []
    const rordStudents = []
    const nrordStudents = []
    const inStudentSnap = await db.collection('/users').where('role', '==', 'student').where('rid', '==', rid).get()
    const inStudentData = inStudentSnap.docs.map(doc => {
      const d = doc.data()
      if (d.rord) {
        rords.push(d.rord)
        rordStudents.push({ ...d, id: doc.id, key: doc.id })
      } else {
        nrordStudents.push({ ...d, id: doc.id, key: doc.id })
      }
      return {
        ...d,
        id: doc.id,
        key: doc.id
      }
    })
    rordStudents.sort((x, y) => (x?.rord ?? 99) - (y?.rord ?? 99))
    setInStudents(inStudentData)

    const snap = await db.collection('/rooms').doc(rid).get()
    const data = snap.data()

    const remainRord = [...Array(data.total).keys()].reduce((acc, o) => {
      return (rords.indexOf(o + 1) === -1) ? [...acc, o + 1] : acc
    }, [])
    setRemainBed(remainRord)


    const orderStudent = [...Array(data.total).keys()].reduce((acc, o) => {
      let re
      if (rordStudents.length && (rordStudents[0].rord === (o + 1))) {
        re = rordStudents[0]
        rordStudents.shift()
      } else if (nrordStudents.length) {
        re = nrordStudents[0]
        nrordStudents.shift()
      } else {
        re = { key: o + 1, rord: o + 1 }
      }
      return [...acc, re]
    }, [])
    setStudents(orderStudent)
    return {
      ...data,
      id: snap.id,
      campus: campusObj[data.cid]?.name ?? ''
    }
  }, [rid, updateCount])

  useEffect(() => {
    const list = students
    list.forEach((o, i) => {
      if (o.id) {
        toDataURL(o?.imgurl).then(data => {
          list[i]['image'] = data || NoProfile
        })
      } else {
        list[i]['image'] = Empty
      }
    })
    setStudents(list)
  }, [students])

  const handleAdd = async (formData) => {
    try {
      setLoading(true)
      const remainStudent = inStudents.map((o) => o.id)
      await db.collection('/rooms').doc(rid).set({
        students: [...remainStudent, formData.uid],
      }, {
        merge: true,
      })
      await db.collection('/users').doc(formData.uid).set({
        rid: rid,
        rord: formData.rord,
        roomstart: firebaseApp.firestore.FieldValue.serverTimestamp()
      }, {
        merge: true,
      })
      setLoading(false)
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const handleDelete = async (row) => {
    try {
      setLoading(true)
      const remainStudent = inStudents.reduce((acc, o) => {
        return (o.id !== row.id) ? [...acc, o.id] : acc
      }, [])
      await db.collection('/rooms').doc(rid).set({
        students: remainStudent,
      }, {
        merge: true,
      })
      await db.collection('/users').doc(row.id).set({
        rid: firebaseApp.firestore.FieldValue.delete(),
        rord: firebaseApp.firestore.FieldValue.delete(),
        roomend: firebaseApp.firestore.FieldValue.serverTimestamp()
      }, {
        merge: true,
      })
      setLoading(false)
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const setBedOrder = async (data) => {
    try {
      setLoading(true)
      await db.collection('/users').doc(data.uid).set({
        rord: data.rord
      }, {
        merge: true
      })
      setUpdateCount(updateCount + 1)
      setLoading(false)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const download = async (data) => {
    const docObj = {
      pageOrientation: 'landscape',
      content: [
        {
          table: {
            headerRows: 1,
            widths: [30, 120, '*', 100, 150, 150],
            body: [
              [{ text: `ห้อง ${data.room}`, colSpan: 6, fontSize: 24, bold: true }, {}, {}, {}, {}, {}],
              [
                {
                  text: 'เตียง',
                  bold: true
                },
                {
                  text: 'รูปถ่าย',
                  bold: true
                },
                {
                  text: 'ชื่อ-นามสกุล',
                  bold: true
                },
                {
                  text: 'ชื่อเล่น',
                  bold: true
                },
                {
                  text: 'บริษัท',
                  bold: true
                },
                {
                  text: 'สหกรณ์',
                  bold: true
                }
              ],
              ...students.map((o, i) => ([
                {
                  text: o?.rord ?? '',
                  alignment: 'center'
                },
                {
                  image: o.image,
                  width: 47,
                  margin: [1, 1]
                },
                {
                  text: `${o?.firstname ?? ''} ${o?.lastname ?? ''}`,
                  alignment: 'left'
                },
                {
                  text: o?.nickname ?? ''
                },
                {
                  text: company[o?.companyid]?.name ?? '',
                  alignment: 'left'
                },
                {
                  text: coop[o?.coopid]?.name ?? '',
                  alignment: 'left'
                }
              ]))
            ]
          },
          layout: {
            fillColor: (rowIndex, node, columnIndex) => {
              return (rowIndex === 0) ? '#EB984E' : (rowIndex === 1) ? '#F5CBA7' : null
            }
          }
        }
      ],
      defaultStyle: {
        fontSize: 16,
        font: 'THSarabunNew', // Roboto
        alignment: 'center'
      }
    }
    try {
      await genPdf(docObj, `Room_${data.room}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'เตียง',
      dataIndex: 'rord',
      width: 80,
      align: 'center',
      render: (data, row) => (!row.id) ? (data) : (data) ? (
        <Button
          onClick={() => {
            orderingForm.setFieldsValue({
              rord: row.rord,
              uid: row.id
            })
            setOrdVisible(true)
          }}
        >{data}</Button>
      ) : (
        <Button
          icon={<EditOutlined style={{ fontSize: 14 }} />}
          onClick={() => {
            orderingForm.setFieldsValue({
              rord: row.rord,
              uid: row.id
            })
            setOrdVisible(true)
          }}
        ></Button>
      )
    },
    {
      title: 'รูปถ่าย',
      dataIndex: 'imgurl',
      width: 100,
      render: (data, row) => (
        <Image
          src={(!row.id) ? Empty : (data) ? data : NoProfile}
          width='100px'
          height='auto'
          preview={false}
        />
      )
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname'
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname'
    },
    {
      title: 'ชื่อเล่น',
      dataIndex: 'nickname'
    },
    {
      title: 'บริษัท',
      dataIndex: 'companyid',
      render: data => company[data]?.name ?? ''
    },
    {
      title: 'สหกรณ์',
      dataIndex: 'coopid',
      render: data => coop[data]?.name ?? ''
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <>
          {(isAllowed(role, 'edit', 'rooms')) && (
            <>
              <Button
                style={{ marginRight: '8px' }}
                icon={<PlusOutlined style={{ fontSize: 18 }} />}
                loading={loading}
                disabled={row.id}
                onClick={() => {
                  studentForm.setFieldsValue({
                    rord: row.rord,
                  })
                  setStudentVisible(true)
                }}
              ></Button>
              <Popconfirm
                title={`Are you sure to remove ${row?.nickname ?? 'this student'}?`}
                onConfirm={() => handleDelete(row)}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  style={{ marginRight: '8px' }}
                  disabled={!row.id}
                  danger
                  loading={loading}
                  icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                ></Button>
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              หอพัก
            </div>
          </Col>
          <Col flex='300px'>
            <div style={{ float: 'right', fontSize: 14 }}>
              {(isAllowed(role, 'edit', 'rooms')) && (
                <Button
                  style={{ marginRight: 12, color: '#FFFFFF', backgroundColor: '#EE7930' }}
                  icon={<EditOutlined style={{ fontSize: 14 }} />}
                  loading={loading}
                  onClick={() => {
                    navigate(`/admin/rooms/${rid}/edit`)
                  }}
                >
                  แก้ไข
                </Button>
              )}
              <Button
                type='primary'
                icon={<DownloadOutlined style={{ fontSize: 14 }} />}
                loading={loading}
                onClick={() => download(value)}
              >
                ดาวน์โหลด
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} className='aiu-title'>วิทยาเขต</Col>
          <Col span={5} className='aiu-content'>{value.campus}</Col>
          <Col span={3} className='aiu-title'>หมายเลขห้อง</Col>
          <Col span={5} className='aiu-content'>{value.room}</Col>
          <Col span={3} className='aiu-title'>จำนวนเตียง</Col>
          <Col span={5} className='aiu-content'>{value.total}</Col>
        </Row>
        <Table
          bordered
          rowKey='key'
          style={{ overflowX: 'auto', margin: '24px 0 24px' }}
          columns={columns}
          dataSource={students}
        />
      </Card>
      <Modal
        forceRender
        open={studentVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            เลือกเตียง
          </div>
        }
        width='600px'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => setStudentVisible(false)}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = studentForm.getFieldsValue()
                    if (data?.uid) {
                      handleAdd(data)
                    }
                    setStudentVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={{}}
          style={{ margin: '8px 32px' }}
          form={studentForm}
          name='studentForm'
        >
          <AntdForm.Item name='rord' hidden={true}>
            <Input />
          </AntdForm.Item>
          <Row gutter={[24, 24]}>
            <Col span={2}></Col>
            <Col span={20}>
              <AntdForm.Item name='uid' label='นักเรียน'>
                <Select
                  showSearch
                  placeholder='กรุณาใส่ชื่อหรือรหัสนักเรียน'
                  optionFilterProp='children'
                  filterOption={(inputValue, item) => (
                    (item.scn || '').indexOf(inputValue) !== -1 ||
                    ((item.nickname || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1 ||
                    ((item.firstname || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1 ||
                    ((item.lastname || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1
                  )}
                  options={studentList.map((o) => ({
                    key: o.id,
                    value: o.id,
                    label: `${o?.scn ?? ''} ${o.firstname} ${o.lastname}`,
                    scn: o.scn,
                    firstname: o.firstname,
                    lastname: o.lastname,
                    nickname: o.nickname
                  }))}
                />
              </AntdForm.Item>
            </Col>
            <Col span={2}></Col>
          </Row>
        </AntdForm>
      </Modal>
      <Modal
        forceRender
        open={ordVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            เลือกเตียง
          </div>
        }
        width='400px'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => setOrdVisible(false)}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = orderingForm.getFieldsValue()
                    setBedOrder(data)
                    setOrdVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={{}}
          style={{ margin: '8px 32px' }}
          form={orderingForm}
          name='orderingForm'
        >
          <AntdForm.Item name='uid' hidden={true}>
            <Input />
          </AntdForm.Item>
          <Row gutter={[24, 24]}>
            <Col span={4}></Col>
            <Col span={16}>
              <AntdForm.Item name='rord' label='เตียง' >
                <Select placeholder='เลือก' >
                  {remainBed.map((o) => (
                    <Select.Option key={o} value={o}>{o}</Select.Option>
                  ))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={4}></Col>
          </Row>
        </AntdForm>
      </Modal>
    </div>
  )
}

export default View
